import styled, { keyframes, css } from "styled-components";

import { device } from "../../../../../../utils/breakpoints";
import {
  fadeIn,
  fadeOut,
  slideFromTop,
  slideToLeft,
  slideToRight,
} from "../../../../../../utils/animations";
import { PageChange } from "../../../../../common/Scroll";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 160px 24px 88px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${slideFromTop} 300ms ease-out
        `};
  animation-fill-mode: forwards;
`;

interface SlideTransitionProps {
  slideChange: PageChange;
}

export const SlideTransitionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  animation: ${(props: SlideTransitionProps) =>
    props.slideChange
      ? css`
          ${props.slideChange === "next"
            ? slideToLeft
            : slideToRight} 300ms ease-in
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const TextWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

interface TypingEffectProps {
  slide?: number;
  width?: number;
}

export const moveLeftRightLoop = (width?: number) => keyframes`
  0% {
    left: -16px;
  };

  50% {
    left: calc(${width ? -64 - width : -64}px + 100vw);
  };

  100% {
    left: -16px;
  };
`;

export const TitleWrapper = styled.div`
  position: relative;
  height: 128px;
  padding: 0;
  margin: 0;

  @media ${device.mobileS} {
    height: 164px;
  } ;
`;

export const TitleScrollEffect = styled.div`
  position: absolute;
  top: 0;
  left: -16px;
  padding: 0;
  margin: 0;
  color: #013cff;
  font-size: 128px;
  line-height: 90%;
  font-weight: 800;
  white-space: pre;
  overflow: hidden;
  animation: ${(props: TypingEffectProps) => moveLeftRightLoop(props.width)} 7s
    ease-in-out infinite;
  animation-fill-mode: forwards;

  @media ${device.mobileS} {
    font-size: 164px;
  } ;
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  padding: 12px 0 18px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: calc(var(--vh, 1vh) * 2.2);
  line-height: calc(var(--vh, 1vh) * 3.4);
  color: #151515;
  white-space: pre-line;
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 25px;
  color: #151515;
  white-space: pre-line;
  padding: 0 0 25px;

  &:last-child {
    padding: 0;
  }
`;

export const ArrowsWrapper = styled.div`
  height: 51px;
  display: grid;
  grid-template-columns: repeat(50%, 2);
  animation: ${fadeIn} 300ms linear;
  animation-fill-mode: forwards;
`;

export const LeftArrowWrapper = styled.div`
  grid-column: 1;

  svg {
    animation: ${fadeIn} 300ms linear;
    ${(props: SlideTransitionProps) =>
      props.slideChange === "previous" &&
      css`
        animation: ${fadeOut} 300ms linear;
      `};
    animation-fill-mode: forwards;
  }
`;

export const RightArrowWrapper = styled.div`
  grid-column: 2;
  margin-left: auto;

  svg {
    animation: ${fadeIn} 300ms linear;
    ${(props: SlideTransitionProps) =>
      props.slideChange === "next" &&
      css`
        animation: ${fadeOut} 300ms linear;
      `};
    animation-fill-mode: forwards;
  }
`;

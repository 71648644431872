import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  SlideTransitionWrapper,
  TextWrapper,
  TitleWrapper,
  TitleScrollEffect,
  DescriptionWrapper,
  Description,
  ArrowsWrapper,
  LeftArrowWrapper,
  RightArrowWrapper,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import ArrowLeft from "-!svg-react-loader!../../../../../../assets/images/arrowLeft.svg";
import ArrowRight from "-!svg-react-loader!../../../../../../assets/images/arrowRight.svg";
import { PageTitle } from "../../../../../common/PageTitle";

interface WhoWeAreMobileProps {
  pageChange: PageChange;
}

const WhoWeAreMobile: React.FC<WhoWeAreMobileProps> = ({ pageChange }) => {
  const { t } = useTranslation("main");

  const descriptions = t(`whoWeAre`) as string[];
  const nOfSlides = descriptions.length - 1;

  const [slide, setSlide] = useState<number>(
    pageChange === "previous" ? nOfSlides : 0
  );
  const [slideTransition, setSlideTransition] = useState<PageChange>();
  const [textWidth, setTextWidth] = useState<number>();

  useEffect(() => {
    const handleWindowResize = () => {
      const elementWidth = document.getElementById("titleScroll")!.clientWidth;
      setTextWidth(elementWidth);
    };

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handlePrevious = () => {
    if (slide > 0) {
      setSlideTransition("previous");
      setTimeout(() => {
        setSlideTransition(undefined);
        setSlide(slide - 1);
      }, 300);
    }
  };

  const handleNext = () => {
    if (slide < nOfSlides) {
      setSlideTransition("next");
      setTimeout(() => {
        setSlideTransition(undefined);
        setSlide(slide + 1);
      }, 300);
    }
  };

  const renderDescriptions = () =>
    descriptions.map((description, index) => (
      // <DescriptionWithNumberWrapper key={index}>
      //   <Number>{numberToFixedLength({ number: index + 1, length: 2 })}</Number>
      <div
        key={index}
        dangerouslySetInnerHTML={{
          __html: fixConjunctions(description),
        }}
      />
      // </DescriptionWithNumberWrapper>
    ));

  return (
    <>
      <PageTitle subtitle={t(`whoWeAre_title`)} />
      <PageWrapper animation={pageChange}>
        <TextWrapper>
          <TitleWrapper>
            <TitleScrollEffect id="titleScroll" slide={slide} width={textWidth}>
              Who we are
            </TitleScrollEffect>
          </TitleWrapper>
          <DescriptionWrapper>
            {/* <SlideTransitionWrapper slideChange={slideTransition}>
            <Description>
              {fixConjunctions(descriptions[slide])}
            </Description>
          </SlideTransitionWrapper> */}
            {renderDescriptions()}
          </DescriptionWrapper>
        </TextWrapper>
        {/* <ArrowsWrapper>
        <LeftArrowWrapper
          slideChange={slide === 1 ? slideTransition : undefined}
        >
          {slide > 0 && <ArrowLeft onClick={handlePrevious} />}
        </LeftArrowWrapper>
        <RightArrowWrapper
          slideChange={slide === nOfSlides - 1 ? slideTransition : undefined}
        >
          {slide < nOfSlides && <ArrowRight onClick={handleNext} />}
        </RightArrowWrapper>
      </ArrowsWrapper> */}
      </PageWrapper>
    </>
  );
};

export default WhoWeAreMobile;
